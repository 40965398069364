<template>
  <component :is="whitepaper === 'long' && !short ? 'Long' : 'Short'" :to="computedTo" />
</template>

<script setup lang="ts">
import useLocalePdfPath from "~/composables/useLocalePdfPath";

import Long from './Long.vue';
import Short from './Short.vue';

import { PATHS } from '~/constants/nav-items'
import { PDF_PATHS } from "~/constants/pdf-paths";

defineOptions({
  components: {
    Long,
    Short,
  },
});

const props = withDefaults(
  defineProps<{
    to?: string;
    short: boolean;
  }>(),
  {
    short: false,
  }
);

const { path, query: { whitepaper, asset } } = useRoute();

const localePdfPath = useLocalePdfPath();

const computedTo = computed(() => {
  // remove condition after IT released
  if (path.includes(PATHS.TECHNICAL_PAPERS.path) && asset === 'it') {
    return PDF_PATHS.NCC.it
  } 

  return props.to || localePdfPath("DOCUMENT_3_REASONS");
});
</script>
